import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { db } from '../firebase';
import { getDoc, doc } from 'firebase/firestore';
import DrawerSide from '../stories/layout-components/DrawerSide';
import { toast } from 'react-toastify';
import moment from 'moment';
import nodeAxiosFirebase from '../utils/nodeAxiosFirebase';
import FieldComponent from '../components/@generalComponents/FieldComponent';
import ElementDetailsContent from '../screens/mainPages/ElementDetailsContent';
import { Skeleton } from '@mui/material';
import { setGeneralStatus } from '../redux/actions-v2/coreAction';

const Quickview = ({
  item,
  type,
  isDrawerOpen,
  handleDrawerClose,
  handleUpdate,
  handleDelete,
  editing,
}) => {
  const { t, i18n } = useTranslation();
  const [structureId, setStructureId] = useState(
    item?.structureIdentifiant || item?.structureId || null
  );
  const dispatch = useDispatch();
  const [toEdit, setToEdit] = useState(editing || false);
  const currentlangCode = i18n.language;
  const [layout, setLayout] = useState([]);
  const [accTabs, setAccTabs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [cleanedFields, setCleanedFields] = useState([]);

  const [activeIndex, setActiveIndex] = useState(0);

  const elementId = item?.documentPath?.split('/').pop();

  const [data, setData] = useState({});

  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );

  const businessStructures = businessStructure?.structures;
  const structure = businessStructures?.find((s) => s.id === structureId);
  const handleFieldChange = (fieldId, value) => {
    let convertedValue;
    convertedValue = value;
    setData((prevState) => ({ ...prevState, [fieldId]: convertedValue }));
  };

  const layoutInit = accTabs[activeIndex]?.blocks || [];

  useEffect(() => {
    setLayout(layoutInit);
  }, [layoutInit]);

  useEffect(() => {
    const fetchDoc = async () => {
      if (data?.id !== elementId) {
        setIsLoading(true);
      }

      if (!item?.documentPath) {
        return;
      }
      const docRef = doc(db, item?.documentPath);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const itemData = docSnap.data();

        setData({
          id: docSnap.id,
          ...itemData,
          ...itemData?.data,
          dependencyId: itemData?.dependencyId?.id,
          price: itemData?.finances?.price / 10000 || 0,
          tax1: itemData?.finances?.tax1 ?? false,
          tax2: itemData?.finances?.tax2 ?? false,
          tax3: itemData?.finances?.tax3 ?? false,
          fees: itemData?.finances?.fees / 10000 || 0,
          cost: itemData?.finances?.cost / 10000 || 0,
          finances: {
            ...itemData?.finances,
            incomeLine: itemData?.finances?.incomeLine?.path || null,
          },
          dependencyPath: itemData?.dependencyId?.path,
          categoryId: itemData?.categoryId?.id,
          assignedTo: itemData?.assignedTo?.id,
          targetId: itemData?.targetId?.id,
          ownerId: itemData?.ownerId?.id,
          structureId: itemData?.structureId?.id,
          name: itemData?.name,
        });
        setStructureId(itemData?.structureId?.id);
        if (type === 'edit') {
          setToEdit(true);
        }
      } else {
        toast.error(t('error'));
      }
    };
    fetchDoc();
  }, [item?.documentPath]);

  const getDocument = async () => {
    try {
      dispatch(setGeneralStatus({ status: 'loading' }));
      const pageData = await nodeAxiosFirebase({
        t,
        url: 'getDocumentV5',
        errorToast: t('errorLoadingDocument'),
        body: {
          documentPath: item?.documentPath,
          structureId: data?.structureId,
          lang: currentlangCode,
          document: data,
          height: window.innerHeight,
          device: 'desktop',
        },
      });
      setAccTabs(pageData || []);
      setIsLoading(false);
      dispatch(setGeneralStatus({ status: 'success' }));
    } catch (error) {
      console.error('Error fetching data');
      dispatch(setGeneralStatus({ status: 'error', error: error }));
    }
  };

  useEffect(() => {
    if (data?.documentPath && data?.structureId) {
      getDocument();
    }
  }, [data?.documentPath, data?.structureId]);

  const handleSave = async (key, value) => {
    let formatedPath = data?.documentPath.split('/');
    dispatch(setGeneralStatus({ status: 'loading' }));

    if (value !== null && value !== undefined) {
      // Remove the ID from the path
      formatedPath = formatedPath
        .filter((part) => part !== elementId)
        .join('/');

      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `updateFieldV2`,
        body: {
          documentId: elementId,
          elementPath: formatedPath,
          key: key,
          value: value,
        },
      });
      if (key && value && handleUpdate) {
        handleUpdate(key, value);
      }
      dispatch(setGeneralStatus({ status: 'success' }));
    }
  };

  useEffect(() => {
    const filteredFields = structure?.fields?.filter((field) => {
      // Basic filtering
      if (
        field?.typeData === 'hook' ||
        (field?.typeData === 'search' &&
          data?.[field?.value] &&
          field?.value === 'targetId')
      ) {
        return false;
      }

      // Conditional filtering
      if (field?.conditional && field?.conditional?.length > 0) {
        return field?.conditional?.every((condition) => {
          const fieldValue = data[condition.field];
          switch (condition.operator) {
            case '==':
              return fieldValue == condition.value;
            case '!=':
              return fieldValue != condition.value;
            case '>':
              return fieldValue > condition.value;
            case '<':
              return fieldValue < condition.value;
            case 'AND': // Handle AND condition
              return (
                field?.conditional.every((cond) =>
                  evalCondition(cond, data[cond.field])
                ) && true
              );
            case 'OR': // Handle OR condition
              return (
                field?.conditional.some((cond) =>
                  evalCondition(cond, data[cond.field])
                ) || false
              );
            default:
              return true;
          }
        });
      }

      return true; // If no conditions, include the field
    });

    setCleanedFields(filteredFields || []);
  }, [structure, data, elementId]);

  // Helper function to evaluate conditions
  const evalCondition = (condition, fieldValue) => {
    switch (condition.operator) {
      case '==':
        return fieldValue == condition.value;
      case '!=':
        return fieldValue != condition.value;
      case '>':
        return fieldValue > condition.value;
      case '<':
        return fieldValue < condition.value;
      default:
        return true;
    }
  };

  const formatAttribute = (title, attribute) => {
    if (typeof title === 'object') {
      if (title?.seconds) {
        return moment
          .unix(title?.seconds || moment().unix())
          .format('DD MMM YYYY');
      } else if (title?._seconds || moment().unix()) {
        return moment
          .unix(title?._seconds || moment().unix())
          .format('DD MMM YYYY');
      }
    } else if (typeof title === 'number') {
      const fieldValue = structure?.[attribute];
      const field = cleanedFields?.find((field) => field?.value === fieldValue);
      if (field?.typeData === 'status') {
        const selection = field?.selections?.find(
          (selection) => selection?.value === title
        );
        return selection?.label;
      } else {
        return title;
      }
    } else if (typeof title === 'string') {
      return title;
    }
  };

  return (
    <DrawerSide
      title={
        formatAttribute(data?.attribute1, 'attribute1') +
        ' ' +
        formatAttribute(data?.attribute2, 'attribute2')
      }
      subtitle={formatAttribute(data?.attribute3, 'attribute3')}
      handleDrawerClose={handleDrawerClose}
      isDrawerOpen={isDrawerOpen}
      elementName={data?.name}
      setToEdit={setToEdit}
      toEdit={toEdit}
      lastUpdate={data?.lastUpdate}
      elementPath={data?.documentPath}
      elementIden={elementId}
      item={data}
      structure={structure}
      handleRemove={handleDelete}
    >
      {isLoading ? (
        <div>
          <Skeleton variant="rectangular" width="100%" height="60px" />
          <div className="mt-2" />
          <Skeleton variant="rectangular" width="100%" height="20px" />
          <div className="mt-2" />
          <Skeleton variant="rectangular" width="100%" height="20px" />
          <div className="mt-2" />
          <Skeleton variant="rectangular" width="100%" height="140px" />
          <div className="mt-2" />
          <Skeleton variant="rectangular" width="100%" height="40px" />
          <div className="mt-2" />
          <Skeleton variant="rectangular" width="100%" height="40px" />
          <div className="mt-2" />
          <Skeleton variant="rectangular" width="100%" height="120px" />
        </div>
      ) : (
        <div>
          {toEdit ? (
            <div>
              {cleanedFields?.map((field) => (
                <FieldComponent
                  key={field?.value}
                  field={{
                    typeData: field?.typeData,
                    label: field?.name,
                    name: field?.name,
                    selections: field?.selections,
                    value: field?.value,
                    validation: field?.validation,
                  }}
                  value={data?.[field?.value]}
                  onChange={handleFieldChange}
                  handleSave={handleSave}
                />
              ))}
            </div>
          ) : (
            <div>
              <div className="d-flex" style={{ width: '100%' }}>
                {data?.id && data?.structureId && !isLoading && (
                  <ElementDetailsContent
                    elementData={data}
                    elementId={elementId}
                    layout={layout}
                    activeIndex={activeIndex}
                    refreshDoc={getDocument}
                    setLayout={setLayout}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </DrawerSide>
  );
};

export default Quickview;
