import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ListItem, ListItemText } from '@material-ui/core';
import moment from 'moment';
import MainLayoutV2 from '../../layouts/MainLayoutV2';
import Block from '../../stories/layout-components/Block';

const SettingsLocations = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const businessPreference = useSelector((state) => state.core.businessData);

  useEffect(() => {
    setLocations(businessPreference?.locations || []);
  }, []);

  const handleSelectLocation = (location) => {
    setSelectedLocation(location);
  };
  const handleAdd = () => {};

  const handleSave = () => {};

  return (
    <MainLayoutV2
      pageTitle={t('resources')}
      add={handleAdd}
      save={selectedLocation && handleSave}
    >
      <Block height={1} heightPercentage={100} noBorder>
        <div className="row">
          <div className={selectedLocation ? 'col-4' : 'col-12'}>
            {locations?.map((item, index) => (
              <div key={index} className="hover">
                <ListItem
                  dense
                  divider
                  onClick={() => handleSelectLocation(item)}
                >
                  <ListItemText
                    primary={item?.name}
                    secondary={item?.address || t('noAddress')}
                  />
                  <ListItemText
                    primary={moment
                      .unix(
                        item?.lastUpdate?.seconds ||
                          item?.lastUpdate?._seconds ||
                          moment().unix()
                      )
                      .format('DD MMM YYYY')}
                    secondary={''}
                  />
                </ListItem>
              </div>
            ))}
          </div>
          <div className={selectedLocation ? 'col-8' : 'hide'}></div>
        </div>
      </Block>
    </MainLayoutV2>
  );
};

export default SettingsLocations;
