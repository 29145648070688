import { Route, Routes } from 'react-router-dom';

import NotFound from './screens/UX/NotFound';

import HomePage from './screens/website/HomePage';
import LegalTerms from './screens/website/LegalTerms';
import HelpApp from './screens/website/HelpApp';
import Contact from './screens/website/Contact';
import PlanDemo from './screens/website/PlanDemo';
import PageContent from './screens/website/PageContent';
import ModuleContent from './screens/website/ModuleContent';
import TipsContent from './screens/website/TipsContent';
import Portal from './screens/website/Portal';
import Pricing from './screens/website/Pricing';
import ToolsContent from './screens/website/ToolsContent';
import ModulesPage from './screens/website/ModulesPage';
import PublicInvoice from './screens/public/PublicInvoice';
import PublicProject from './screens/public/PublicProject';
import Industries from './screens/website/Industries';
import Welcome from './screens/public/Welcome';
import Presentation from './screens/public/Presentation';
import BusinessPage from './screens/website/BusinessPage';
import Oauth from './screens/UX/Oauth';
import SupportContent from './screens/website/SupportContent';
import PublicProfile from './screens/website/PublicProfile';
import Onboard from './screens/website/Onboard';
import Platform from './screens/website/Platform';
import Approch from './screens/website/Approch';
import Usecases from './screens/website/Usecases';
import StructurePublic from './screens/public/StructurePublic';
import PriceSimulator from './screens/website/PriceSimulator';
import OnboardSuccess from './screens/website/OnboardSuccess';
import GamePlay from './screens/website/GamePlay';
import Event from './screens/website/Event';

const PublicRoutes = () => {
  return (
    <>
      {' '}
      <Routes>
        <Route path="/" element={<HomePage />} />

        <Route path="/comprendre-node" element={<HomePage />} />
        <Route path="/portal" element={<Portal />} />
        <Route path="/informations/mentions-legales" element={<LegalTerms />} />
        <Route path="/card/:domain/:elementId" element={<PublicInvoice />} />
        <Route path="/card/:elementId" element={<PublicInvoice />} />
        <Route path="/welcome/:businessId" element={<Welcome />} />
        <Route
          path="/card-user-public/:elementId"
          element={<PublicInvoice />}
        />
        <Route
          path="/card-project/:domain/:cardId"
          element={<PublicProject />}
        />
        <Route path="/oauth/exchange" element={<Oauth />} />
        <Route path="/page/:businessId" element={<BusinessPage />} />
        <Route
          path="/profile/:userId/:businessId"
          element={<PublicProfile />}
        />
        <Route path="/onboard" element={<Onboard />} />
        <Route path="/event" element={<Event />} />
        <Route path="/onboard-success" element={<OnboardSuccess />} />
        <Route path="/presentation" element={<Presentation />} />
        <Route path="/functions/:contentName" element={<PageContent />} />
        <Route path="/tools/:contentName" element={<ToolsContent />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/pricing/simulator" element={<PriceSimulator />} />
        <Route path="/modules/:contentName" element={<ModuleContent />} />
        <Route path="/lp/:contentName" element={<Industries />} />
        <Route path="/modules" element={<ModulesPage />} />
        <Route path="/tips/:contentName" element={<TipsContent />} />
        <Route path="/play/:gameId" element={<GamePlay />} />
        <Route path="/meet-node" element={<PlanDemo />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/platform" element={<Platform />} />
        <Route path="/approch" element={<Approch />} />
        <Route path="/use-cases" element={<Usecases />} />
        <Route path="/help/app" element={<HelpApp />} />
        <Route path="/help/app/:contentId" element={<SupportContent />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/structure-public" element={<StructurePublic />} />
      </Routes>
    </>
  );
};

export default PublicRoutes;
