// Libraries
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UploadDropzone } from '@bytescale/upload-widget-react';
import { useTheme } from '@mui/material/styles';
import { FormControl, InputLabel, IconButton, Modal, Box } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

// Components

const IconUploader = ({
  onComplete,
  elementId,
  onBlur,
  elementType,
  fieldType,
  label,
  value,
  size,
  required,
  fieldKey,
  businessId,
}) => {
  const { t } = useTranslation();

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const isDarkmode = theme.palette.mode === 'dark';

  const handleComplete = (files) => {
    onComplete(files);
    setUploadedFiles(files);
    if (onBlur) {
      onBlur(files);
    }
    setOpenModal(false);
  };

  useEffect(() => {
    setUploadedFiles(
      fieldType === 'media-single'
        ? value === undefined
          ? []
          : [value]
        : value
    );
  }, [fieldKey, value]);

  const businessPreference = useSelector((state) => state.core.businessData);

  const options = {
    apiKey: 'public_kW15buA4B6U6YzcBgoEsrFxYm4BE',
    maxFileCount: fieldType === 'media-single' ? 1 : 5,
    showFinishButton: true,
    locale: {
      addAnotherFileBtn: t('addAnotherFileBtn'),
      addAnotherImageBtn: t('addAnotherImageBtn'),
      cancelBtn: t('cancelBtn'),
      cancelBtnClicked: t('cancelBtnClicked'),
      cancelPreviewBtn: t('cancelPreviewBtn'),
      continueBtn: t('continueBtn'),
      cropBtn: t('cropBtn'),
      customValidationFailed: t('customValidationFailed'),
      doneBtn: t('doneBtn'),
      fileSizeLimitPrefix: t('fileSizeLimitPrefix'),
      finishBtn: t('finishBtn'),
      finishBtnIcon: true,
      imageCropNumberPrefix: t('imageCropNumberPrefix'),
      maxFilesReachedPrefix: t('maxFilesReachedPrefix'),
      maxImagesReachedPrefix: t('maxImagesReachedPrefix'),
      orDragDropFile: t('orDragDropFile'),
      orDragDropFileMulti: t('orDragDropFileMulti'),
      orDragDropImage: t('orDragDropImage'),
      orDragDropImageMulti: t('orDragDropImageMulti'),
      processingFile: t('processingFile'),
      removeBtn: t('removeBtn'),
      removeBtnClicked: t('removeBtnClicked'),
      submitBtnError: t('submitBtnError'),
      submitBtnLoading: t('submitBtnLoading'),
      unsupportedFileType: t('unsupportedFileType'),
      uploadFileBtn: t('uploadFileBtn'),
      uploadFileMultiBtn: t('uploadFileMultiBtn'),
      uploadImageBtn: t('uploadImageBtn'),
      uploadImageMultiBtn: t('uploadImageMultiBtn'),
      xOfY: 'of',
    },
    path: {
      folderPath: '/public/' + (businessPreference?.id || businessId),
    },
    metadata: {
      elementId: elementId,
      elementType: elementType,
    },
    tags: ['tag1', 'tag2'],
    styles: {
      colors: {
        active: businessPreference?.secColor
          ? businessPreference?.secColor + '15'
          : '#377dff15',
        error: '#d23f4d',
        primary: '#377dff',
        shade100: businessPreference?.mainColor || '#000000',
        shade200: '#7a7a7a',
        shade300: '#999',
        shade400: '#a5a6a8',
        shade500: '#f2f2f2',
        shade600: 'transparent',
        shade700: businessPreference?.mainColor + '20',
        shade800: businessPreference?.mainColor + '10',
        shade900: businessPreference?.mainColor || '#000000',
      },
      fontFamilies: {
        base: '-apple-system, blinkmacsystemfont, Segoe UI, helvetica, arial, sans-serif',
      },
      fontSizes: {
        base: 16,
      },
    },
  };

  return (
    <div>
      {size === 'small' ? (
        <>
          <IconButton onClick={() => setOpenModal(true)} color="primary">
            {value ? (
              <img src={value} width={18} height={18} alt="img" />
            ) : (
              <AddPhotoAlternateIcon />
            )}
          </IconButton>
          <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="icon-uploader-modal"
            aria-describedby="icon-uploader-description"
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                borderRadius: '14px',
                boxShadow: 24,
                p: 4,
              }}
            >
              <FormControl
                fullWidth
                required={required}
                margin="normal"
                sx={{
                  border: '1px solid lightgray',
                  borderRadius: '14px',
                  padding: '10px',
                }}
              >
                <InputLabel
                  shrink
                  required={required}
                  sx={{
                    backgroundColor: isDarkmode ? 'rgb(51,51,51)' : '#FFF',
                    padding: '2px 5px 2px 5px',
                    borderRadius: '14px',
                  }}
                >
                  {label}
                </InputLabel>
                <UploadDropzone
                  options={options}
                  onComplete={handleComplete}
                  width="100%"
                  height="120px"
                />
              </FormControl>
            </Box>
          </Modal>
        </>
      ) : (
        <FormControl
          fullWidth
          required={required}
          margin="normal"
          sx={{
            border: '1px solid lightgray',
            borderRadius: '14px',
            padding: '10px',
          }}
        >
          <InputLabel
            shrink
            required={required}
            sx={{
              backgroundColor: isDarkmode ? 'rgb(51,51,51)' : '#FFF',
              padding: '2px 5px 2px 5px',
              borderRadius: '14px',
            }}
          >
            {label}
          </InputLabel>
          <UploadDropzone
            options={options}
            onComplete={handleComplete}
            width="100%"
            height={uploadedFiles?.length === 0 ? '120px' : '180px'}
          />
          {uploadedFiles?.length > 0 &&
            uploadedFiles?.map((file, index) => (
              <div key={index} className="col-2 align-left p-2">
                <img
                  src={file.fileUrl || file}
                  alt="file"
                  style={{ width: '50px', height: '50px', borderRadius: 5 }}
                />
              </div>
            ))}
        </FormControl>
      )}
    </div>
  );
};

export default IconUploader;
