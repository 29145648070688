import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  FormControl,
  InputLabel,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  ListItem,
} from '@mui/material';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import { db } from '../../firebase';
import {
  collection,
  query,
  onSnapshot,
  where,
  doc,
  getDocs,
} from 'firebase/firestore';
import TextField from '@mui/material/TextField';
import { t } from 'i18next';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

export const SelectAutoComplete = ({
  variant = 'outlined',
  parentElementCollection,
  parentElementPath,
  label,
  fieldKey,
  fieldType,
  value,
  creationTargetId,
  onChange,
  allowNew,
  error,
  selections,
  required,
  size,
}) => {
  const businessPreference = useSelector((state) => state.core.businessData);
  const businessId = businessPreference?.id;
  const theme = useTheme();
  const isDarkmode = theme.palette.mode === 'dark';

  const [selectionsOptions, setSelectionsOptions] = useState(selections || []);
  const [selectedValue, setSelectedValue] = useState(null);
  const [storages, setStorages] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  const parentElementPathArray =
    typeof parentElementPath === 'string' && parentElementPath?.split('/');

  const parentElementPathEnd =
    typeof parentElementPath === 'string' && parentElementPathArray?.pop();
  const isBusinessId = parentElementPathEnd === businessId;

  const getDocuments = async () => {
    const collectionFieldQuery =
      'users/' +
      creationTargetId +
      '/connections/' +
      creationTargetId +
      businessId +
      '/profiles';

    const targetRef = doc(db, 'users', creationTargetId);
    const businessRef = doc(db, 'businessesOnNode', businessId);

    const q = query(
      collection(db, collectionFieldQuery),
      where('targetId', '==', targetRef),
      where('ownerId', '==', businessRef)
    );

    try {
      // Retrieve the documents once
      const querySnapshot = await getDocs(q);

      let data = [];
      querySnapshot.forEach((doc) => {
        data.push({
          id: doc.id,
          label:
            doc.data().attribute1 +
            ' ' +
            doc.data().attribute2 +
            ' ' +
            doc.data().attribute3,
          subLabel: doc.data().name,
          value: doc.id || doc?.documentIdentifiant,
          structureId: doc.data().structureId?.id || '',
          targetId: doc.data().targetId?.id || '',
          ownerId: doc.data().ownerId?.id || '',
          dependencyId: doc.data().dependencyId?.id || '',
        });
      });

      setSelectionsOptions(data);
    } catch (error) {
      console.error('Error getting documents:', error);
    }
  };

  useEffect(() => {
    if (isBusinessId) {
      setSelectionsOptions(
        businessPreference?.profiles?.map((option) => {
          return {
            label:
              (option?.attribute1 || '') +
              ' ' +
              (option?.attribute2 || '') +
              ' ' +
              (option?.attribute3 || ''),
            subLabel: option?.name || '',
            value: option?.id || option?.documentIdentifiant,
          };
        })
      );
    } else if (fieldKey && parentElementPath && parentElementPathEnd) {
      getDocuments();
    }
  }, [parentElementPath]);

  const getDocumentsSub = async () => {
    const collectionFieldQuerySubStorages =
      'users/' +
      creationTargetId +
      '/connections/' +
      creationTargetId +
      businessId +
      '/profiles/' +
      selectedValue +
      '/storages';

    const collectionFieldQuerySubTasks =
      'users/' +
      creationTargetId +
      '/connections/' +
      creationTargetId +
      businessId +
      '/profiles/' +
      selectedValue +
      '/tasks';

    const targetRef = doc(db, 'users', creationTargetId);
    const businessRef = doc(db, 'businessesOnNode', businessId);
    const q = query(
      collection(db, collectionFieldQuerySubStorages),
      where('targetId', '==', targetRef),
      where('ownerId', '==', businessRef)
    );

    const qTask = query(
      collection(db, collectionFieldQuerySubTasks),
      where('targetId', '==', targetRef),
      where('ownerId', '==', businessRef),
      where('isDone', '==', false)
    );

    const querySnapshot = await getDocs(q);
    const querySnapshotTasks = await getDocs(qTask);
    const fetchedStorages = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      label:
        doc.data().attribute1 +
        ' ' +
        doc.data().attribute2 +
        ' ' +
        doc.data().attribute3,
      subLabel: doc.data().name,
      value: doc.id,
      structureId: doc.data().structureId?.id || '',
      targetId: doc.data().targetId?.id || '',
      ownerId: doc.data().ownerId?.id || '',
      dependencyId: doc.data().dependencyId?.id || '',
    }));

    const fetchedTasks = querySnapshotTasks.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      label:
        doc.data().attribute1 +
        ' ' +
        doc.data().attribute2 +
        ' ' +
        doc.data().attribute3,
      subLabel: doc.data().name,
      value: doc.id,
      structureId: doc.data().structureId?.id || '',
      targetId: doc.data().targetId?.id || '',
      ownerId: doc.data().ownerId?.id || '',
      dependencyId: doc.data().dependencyId?.id || '',
    }));

    setStorages(fetchedStorages);
    setTasks(fetchedTasks);
  };

  const onSelectionChange = (event, fieldType, newValueId, type) => {
    onChange(event, fieldType, newValueId, type);
    setSelectedValue(newValueId);
  };

  const handleQuickElements = () => {
    setDialogOpen(true);
    if (selectedValue) {
      const unsubscribe = getDocumentsSub();
      return () => unsubscribe();
    }
  };

  useEffect(() => {
    if (!selections) {
      setSelectionsOptions([]);
      setSelectedValue(null);
    }
    setStorages([]);
  }, [parentElementPath, creationTargetId]);

  return (
    <div className="d-flex middle-content">
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogContent>
          <div style={{ minWidth: '400px' }}>
            <div>
              {storages?.map((storage) => (
                <ListItem divider key={storage?.id}>
                  <div>
                    <div>
                      <Typography>{storage?.label}</Typography>
                    </div>
                    <div>
                      <Typography variant="caption" color="textSecondary">
                        {storage?.subLabel}
                      </Typography>
                    </div>
                  </div>
                </ListItem>
              ))}
            </div>
            <div className="mt-4">
              {tasks?.map((task) => (
                <ListItem divider key={task?.id}>
                  <div>
                    <div>
                      <Typography>{task?.label}</Typography>
                    </div>
                    <div>
                      <Typography variant="caption" color="textSecondary">
                        {task?.subLabel}
                      </Typography>
                    </div>
                  </div>
                </ListItem>
              ))}
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <div className={selectedValue && !selections ? 'col-11' : 'col-12'}>
        <FormControl fullWidth margin="normal">
          <InputLabel
            shrink={true}
            required={required || false}
            error={error}
            sx={{
              backgroundColor: isDarkmode ? 'rgb(51,51,51)' : '',
              padding: '2px 10px 2px 10px',
              borderRadius: '14px',
            }}
          >
            {label}
          </InputLabel>
          <Autocomplete
            options={selectionsOptions}
            disabled={!parentElementPath && !selections}
            error={error}
            noOptionsText={t('noOption')}
            getOptionLabel={(option) =>
              `${
                option?.label ||
                selectionsOptions?.find((opt) => opt?.value === selectedValue)
                  ?.label ||
                '-'
              }`
            }
            value={value}
            onChange={(event, newValue) => {
              if (newValue) {
                onSelectionChange(event, fieldType, newValue.id, 'profiles');
              }
            }}
            freeSolo={allowNew}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option.title
              );
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  value: inputValue,
                  label: `${t('new')} : ${inputValue}`,
                  id: inputValue,
                });
              }

              return filtered;
            }}
            selectOnFocus={allowNew}
            clearOnBlur={allowNew}
            handleHomeEndKeys={allowNew}
            renderOption={(props, option) => (
              <Box
                sx={{
                  height: '41px',
                  textAlign: 'left',
                  position: 'relative',
                  zIndex: 1000,
                }}
                {...props}
              >
                <Box sx={{ height: '41px', textAlign: 'left' }}>
                  <Typography textAlign="left">{option?.label}</Typography>
                  <Typography
                    textAlign="left"
                    variant="caption"
                    color="textSecondary"
                    sx={{ textAlign: 'left', marginTop: '-5px' }}
                  >
                    {option?.subLabel || ''}
                  </Typography>
                </Box>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                error={error}
                disabled={!parentElementPath}
                sx={{
                  'MuiFormControl-root': {
                    width: '100%',
                  },
                  '.MuiInputBase-input': {
                    height: '12px',
                    fontSize: size === 'small' ? '11px' : '13px',
                  },
                  '& .MuiFormLabel-root': {
                    backgroundColor: isDarkmode ? 'rgb(51,51,51)' : '#FFF',
                    borderRadius: '14px',
                  },
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '14px',
                    '&.Mui-focused fieldset': {
                      borderColor: businessPreference?.mainColor || '#000',
                      boxShadow: `0 0 0 0.2rem ${
                        businessPreference?.mainColor + '20'
                      }`,
                    },
                  },
                }}
              />
            )}
          />
        </FormControl>
      </div>
      <div className={selectedValue && !selections ? 'col-1' : 'hide'}>
        {!selections && (
          <IconButton onClick={handleQuickElements}>
            <BoltOutlinedIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default SelectAutoComplete;
