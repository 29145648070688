import React, { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ErrorBoundary from '../../components/@generalComponents/ErrorBoundary';
import * as modalActions from '../../redux/actions/modal-actions';
import * as drawerActions from '../../redux/actions-v2/drawer-actions';
import * as Icons from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Divider,
  Typography,
  Menu,
  MenuItem,
  Tooltip,
  Button,
  FormControl,
} from '@mui/material';
import ListLayout from '../children-components/ListLayout';
import ContentLayout from '../children-components/ContentLayout';
import ActionLayout from '../children-components/ActionLayout';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import {
  Edit,
  ReplayOutlined,
  MoreVert,
  FitScreenOutlined,
  FilterListOutlined,
  PodcastsOutlined,
  InfoOutlined,
  Add,
  GroupOutlined,
  CheckCircle,
  EditOutlined,
  LockOutlined,
  ArrowDownward,
  ArrowUpward,
  HourglassBottom,
} from '@mui/icons-material';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Autocomplete } from '@mui/material';
import TextFieldMUI from '@mui/material/TextField';
import Lottie from 'react-lottie';
import animationData from '../../lotties/empty-box';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase';
import GeneralText from '../general-components/GeneralText';
import { InputLabel } from '@material-ui/core';
import TextField from '../general-components/TextField';
import { useParams } from 'react-router';
import Select from '../general-components/Select';
import { setGeneralStatus } from '../../redux/actions-v2/coreAction';
import DialogWindow from '../general-components/DialogWindow';
import DrawerSide from './DrawerSide';
import RequestChanges from './RequestChanges';

const BlockLayoutScroll = ({
  elementDetails,
  childrenComponent,
  heightPercentage,
  setUpdatedPrimaryData,
  fromList,
  handleClose,
  layout,
  handleAddItem,
}) => {
  return (
    <ErrorBoundary>
      {childrenComponent === 'list' ? (
        <ListLayout elementDetails={elementDetails} layout={layout} />
      ) : childrenComponent === 'content' ? (
        <ContentLayout
          elementDetails={elementDetails}
          layout={layout}
          setUpdatedPrimaryData={setUpdatedPrimaryData}
        />
      ) : childrenComponent === 'action' ? (
        <ActionLayout
          elementDetails={elementDetails}
          fromList={fromList}
          layout={layout}
          heightPercentage={heightPercentage}
          handleAddItem={handleAddItem}
        />
      ) : null}
    </ErrorBoundary>
  );
};

const BlockLayout = ({
  className,
  noPadding,
  heightPercentage,
  layout,
  noScroll,
  handleClose,
  activeIndex,
  setDrag,
  fromList,
  handleEditBlockContent,
  handleRemoveBlock,
  childrenComponent,
  editMode,
  elementDetails,
}) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { structureId } = useParams();
  const currentLangCode = i18n.language;
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const [blockData, setBlockData] = useState({
    name: '',
    filter: {},
    createBtn: false,
    editBtn: false,
    refreshBtn: false,
    icon: '',
    infos: {},
    selectedColor: 'white',
    bgPattern: '',
    changesInProgress: [],
  });
  const [blockDialog, setBlockDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [formData, setFormData] = useState({});
  const [menuOpen, setMenuOpen] = useState(false);
  const [elementDetailsTransit, setElementDetailsTransit] = useState({});
  const [isLoadingChanges, setIsLoadingChanges] = useState(false);
  const [openDrawerChanges, setOpenDrawerChanges] = useState(false);
  const [blockTransit, setBlockTransit] = useState({});
  const businessPreference = useSelector((state) => state.core.businessData);
  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );
  const currentUser = useSelector((state) => state.core.user);
  const activeUser = businessPreference?.employees?.find(
    (employee) => employee.id === currentUser?.uid
  );

  const businessStructures = businessStructure?.structures;
  const currentIconStructure = businessStructures?.find(
    (s) => s.id === layout?.structureId
  )?.icon;

  const CurrentIcon = Icons[currentIconStructure] || Icons.FolderOutlined;
  const currentNameStructure = businessStructures?.find(
    (s) => s.id === layout?.structureId
  )?.name;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const formattedGroups = businessPreference?.groups?.map((group) => ({
    label: group?.name,
    value: group?.identifiant,
    id: group?.identifiant,
  }));

  const isDarkMode = theme.palette.mode === 'dark';
  const mainColor = theme.palette.primary.main;
  const secColor = theme.palette.secondary.main;

  //darker
  const darkColor = chroma(mainColor).darken(0.8).hex();
  const darkSecColor = chroma(secColor).darken(0.8).hex();

  useEffect(() => {
    if (elementDetails?.header) {
      setBlockData({
        ...elementDetails?.header,
        color: layout.color,
        bgPattern: layout?.bgPattern,
        infos: elementDetails?.block?.infos?.display
          ? elementDetails?.block?.infos
          : null,
        changesInProgress: elementDetails?.header?.changesInProgress,
        groups:
          elementDetails?.groups?.length > 0
            ? elementDetails?.groups?.map((group) => ({
                label: businessPreference?.groups?.find(
                  (g) => g.identifiant === group
                )?.name,
                value: group,
                id: group,
              }))
            : null,
      });
    }

    if (elementDetails?.data) {
      setBlockTransit(elementDetails);
      setElementDetailsTransit(elementDetails?.data);
    }
  }, [elementDetails]);

  const handleAddItem = async (item, option, group, type, quantity) => {
    dispatch(
      setGeneralStatus({
        status: 'loading',
        position: group ? group : 'add-item',
        type: 'skeleton',
      })
    );
    await nodeAxiosFirebase({
      t,
      method: 'POST',
      url: `mainCreationV6`,
      body: {
        name: t('item'),
        tags: [],
        elementPath: layout?.mainElement?.documentPath + '/items',
        structureId: layout?.structureId,
        blockStructure: structureId,
        tabIndex: activeIndex,
        blockIdentifiant: layout?.i,
        lang: currentLangCode,
        data: {
          status: 0,
          targetId: 'users/' + layout?.mainElement?.targetId,
          dependencyId: layout?.mainElement?.documentPath,
          targetProfileId: layout?.mainElement?.targetProfileId
            ? 'profiles/' + layout?.mainElement?.targetProfileId
            : null,
          hookedWith: item?.hookedId
            ? item?.hookedId
            : type === 0
            ? 'services/' + item?.id || item?.objectID
            : 'articles/' + item?.id || item?.objectID,
          quantity: quantity || 1,
          group: group || '',
          options: option
            ? [
                {
                  name: option?.name,
                  price: option?.price,
                  tax1: option?.tax1 || true,
                  tax2: option?.tax2 || true,
                },
              ]
            : [],
        },
      },
    });
    dispatch(setGeneralStatus({ status: 'success' }));
  };
  const handleUpdateRefresh = async () => {
    dispatch(setGeneralStatus({ status: 'loading' }));
    handleCloseMenu();

    const pathWithoutId = layout?.mainElement?.documentPath?.split('/');
    pathWithoutId.pop();
    const path = pathWithoutId.join('/');
    await nodeAxiosFirebase({
      t,
      method: 'POST',
      url: `refreshOriginal`,
      body: {
        elementPath: layout?.mainElement?.documentPath,
      },
    });
    dispatch(setGeneralStatus({ status: 'success' }));
  };

  const openCreateModal = () => {
    if (layout?.type === 'mainCardItems' || layout?.type === 'secCardItems') {
      dispatch(
        modalActions.modalAddItem({
          isOpen: true,
          group: null,
          defaultTab: 0,
          onSelect: handleAddItem,
        })
      );
    } else {
      const match = layout?.match?.split(':')[0];

      dispatch(
        modalActions.modalElementCreation({
          isOpen: true,
          fromElement: true,
          structureId: layout?.structureId,
          elementPath: layout?.mainElement?.documentPath || null,
          dependencyId:
            match === 'targetProfileId'
              ? 'profiles/' + layout?.mainElement?.targetProfileId
              : match === 'targetId'
              ? 'users/' + layout?.mainElement?.targetId
              : layout?.mainElement?.documentPath || null,
          targetProfileId:
            match === 'targetProfileId'
              ? layout?.mainElement?.targetProfileId
              : null,
          targetId: layout?.mainElement?.targetId,
          blockLayoutDetails: {
            blockIdentifiant: layout?.i,
            blockStructure: structureId,
            tabIndex: activeIndex,
          },
          handleDone: (data) => handleUpdateElement(data),
        })
      );
    }
  };

  const handleUpdateElement = (data) => {
    const newElement = elementDetailsTransit;
    newElement.push([...data]);
    setBlockTransit({ ...blockTransit, data: newElement });
  };

  const handleUpdateElementDrawer = (key, value) => {
    const indexToUpdate = layout?.data?.findIndex(
      (element) => element?.structureValue === key
    );

    const newElement = elementDetailsTransit;
    newElement[indexToUpdate]['value'] = value;
    newElement[indexToUpdate]['transformedValue'] = value;

    setBlockTransit({ ...blockTransit, data: newElement });
  };

  const handleRemove = () => {
    handleRemoveBlock(layout?.i);
  };

  const openDrawerEdit = () => {
    if (layout?.data[0]?.primaryData?.elementPath) {
      dispatch(
        drawerActions.viewElement({
          isDrawerOpen: true,
          item: {
            id: layout?.data[0]?.primaryData?.elementId,
            documentPath: layout?.data[0]?.primaryData?.elementPath,
            name: layout?.data[0]?.primaryData?.name,
            targetId: layout?.data[0]?.primaryData?.targetId,
            lastUpdate: layout?.data[0]?.primaryData?.lastUpdate,
          },
          handleDrawerClose: handleClose,
          type: 'edit',
          handleUpdate: (key, value) => handleUpdateElementDrawer(key, value),
        })
      );
    }
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleBlock = () => {
    handleCloseMenu();
    setBlockDialog(true);
  };

  const handleCloseBlockDialog = () => {
    setBlockDialog(false);
  };

  const handleEditBlock = () => {
    handleCloseMenu();
    if (childrenComponent === 'list') {
      handleEditBlockContent({
        ...layout,
        data: layout?.data[0]?.map((element) => {
          return {
            action: element?.action,
            fieldType: element?.fieldType,
            weight: element?.weight,
            label: element?.label,
            typeValue: element?.typeValue,
            selections: element?.selections || [],
            sub: element?.sub || {},
            type: element?.typeValue,
            structureValue: element?.structureValue,
            value: element?.value,
            width: element?.width || 30,
            valueColor: element?.valueColor || 'primary',
          };
        }),
      });
    } else {
      handleEditBlockContent(layout);
    }
  };

  const handleRequestChanges = () => {
    setOpenDrawerChanges(true);
  };

  const handleSaveChanges = async () => {
    setIsLoadingChanges(true);
    try {
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business-requestChanges`,
        body: {
          structureId: structureId,
          lang: currentLangCode,
          path: window.location.pathname,
          data: {
            tabIndex: activeIndex,
            blockIdentifiant: layout?.i,
            changes: formData,
            blockData: {
              ...blockData,
            },
          },
        },
      });
      setIsLoadingChanges(false);
      handleCloseChanges();
      setBlockData({
        ...blockData,
        changesInProgress: [
          {
            status: 'in-progress',
            tab: activeIndex,
            block: layout?.i,
          },
          ...blockData?.changesInProgress,
        ],
      });
    } catch (error) {
      console.error('Error set block');
      dispatch(setGeneralStatus({ status: 'error', error: error }));
    }
  };

  const handleCloseChanges = () => {
    setOpenDrawerChanges(false);
    setFormData({});
  };

  const handleSaveBlock = async () => {
    setBlockDialog(false);

    const transformedGroups = blockData?.groups?.map((group) => group.value);
    try {
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business-setModuleStructure`,
        body: {
          type: 'block',
          structureId: structureId,
          lang: currentLangCode,
          data: {
            tabIndex: activeIndex,
            blockIdentifiant: layout?.i,
            blockData: {
              ...blockData,
              filter: blockData?.filter?.operator ? blockData?.filter : null,
              infos: blockData?.infos?.display ? blockData?.infos : null,
              groups: transformedGroups?.length > 0 ? transformedGroups : [],
              color: blockData?.color,
              bgPattern: blockData?.bgPattern,
            },
          },
        },
      });
    } catch (error) {
      console.error('Error set block');
      dispatch(setGeneralStatus({ status: 'error', error: error }));
    }
  };

  return (
    <ErrorBoundary>
      <DialogWindow
        title={t('updateBlock')}
        open={blockDialog}
        onClose={handleCloseBlockDialog}
        size={'medium'}
        width={'medium'}
        button={{
          action: handleSaveBlock,
          label: t('save'),
        }}
      >
        <div style={{ minHeight: '100px' }}>
          <div className="align-c d-flex">
            {childrenComponent !== 'content' && (
              <Button
                sx={{ width: '90px', margin: '8px' }}
                onClick={() =>
                  setBlockData({
                    ...blockData,
                    createBtn: !blockData?.createBtn,
                  })
                }
              >
                {blockData?.createBtn && (
                  <div style={{ top: 0, right: 0, position: 'absolute' }}>
                    <CheckCircle color="success" />
                  </div>
                )}
                <div>
                  <Add />
                  <Typography fontSize="8px" fontWeight={400}>
                    {t('create')}
                  </Typography>
                </div>
              </Button>
            )}
            {childrenComponent === 'content' && (
              <Button
                sx={{ width: '90px', margin: '8px' }}
                onClick={() =>
                  setBlockData({
                    ...blockData,
                    editBtn: !blockData?.editBtn,
                  })
                }
              >
                {blockData?.editBtn && (
                  <div style={{ top: 0, right: 0, position: 'absolute' }}>
                    <CheckCircle color="success" />
                  </div>
                )}
                <div>
                  <Edit />
                  <Typography fontSize="8px" fontWeight={400}>
                    {t('edit')}
                  </Typography>
                </div>
              </Button>
            )}
            {childrenComponent === 'content' && (
              <Button
                sx={{ width: '90px', margin: '8px' }}
                onClick={() =>
                  setBlockData({
                    ...blockData,
                    refreshBtn:
                      blockData?.refreshBtn === 'user' ? 'none' : 'user',
                  })
                }
              >
                {blockData?.refreshBtn !== 'none' && (
                  <div style={{ top: 0, right: 0, position: 'absolute' }}>
                    <CheckCircle color="success" />
                  </div>
                )}
                <div>
                  <ReplayOutlined />
                  <Typography fontSize="8px" fontWeight={400}>
                    {t('refresh')}
                  </Typography>
                </div>
              </Button>
            )}
            {childrenComponent === 'list' && (
              <Button
                sx={{ width: '90px', margin: '8px' }}
                onClick={() =>
                  setBlockData({
                    ...blockData,
                    filter: blockData?.filter?.operator
                      ? {}
                      : { operator: '==' },
                  })
                }
              >
                {blockData?.filter?.operator && (
                  <div style={{ top: 0, right: 0, position: 'absolute' }}>
                    <CheckCircle color="success" />
                  </div>
                )}
                <div>
                  <FilterListOutlined />
                  <Typography fontSize="8px" fontWeight={400}>
                    {t('filter')}
                  </Typography>
                </div>
              </Button>
            )}
            {childrenComponent === 'list' && (
              <Button
                sx={{ width: '90px', margin: '8px' }}
                onClick={() =>
                  setBlockData({
                    ...blockData,
                    order: blockData?.order?.direction
                      ? {}
                      : { direction: 'desc' },
                  })
                }
              >
                {blockData?.order?.direction && (
                  <div style={{ top: 0, right: 0, position: 'absolute' }}>
                    <CheckCircle color="success" />
                  </div>
                )}
                <div>
                  <ArrowDownward />
                  <Typography fontSize="8px" fontWeight={400}>
                    {t('order')}
                  </Typography>
                </div>
              </Button>
            )}
            <Button
              sx={{ width: '90px', margin: '8px' }}
              onClick={() =>
                setBlockData({
                  ...blockData,
                  groups: [],
                })
              }
            >
              {blockData?.groups && (
                <div style={{ top: 0, right: 0, position: 'absolute' }}>
                  <CheckCircle color="success" />
                </div>
              )}
              <div>
                <GroupOutlined />
                <Typography fontSize="8px" fontWeight={400}>
                  {t('groups')}
                </Typography>
              </div>
            </Button>
            <Button
              sx={{ width: '90px', margin: '8px' }}
              onClick={() =>
                setBlockData({
                  ...blockData,
                  infos: { display: !blockData?.infos?.display },
                })
              }
            >
              {blockData?.infos?.display && (
                <div style={{ top: 0, right: 0, position: 'absolute' }}>
                  <CheckCircle color="success" />
                </div>
              )}

              <div>
                <InfoOutlined />
                <Typography fontSize="8px" fontWeight={400}>
                  {t('infos')}
                </Typography>
              </div>
            </Button>
          </div>

          <div className="col-12">
            <TextField
              label={t('name')}
              value={blockData?.name}
              onChange={(e) =>
                setBlockData({ ...blockData, name: e.target.value })
              }
              fullWidth
            />
          </div>
          <div className="d-flex">
            <div className="col-5 mt-1">
              <FormControl
                fullWidth
                margin="normal"
                sx={{
                  border: '1px solid lightgray',
                  borderRadius: '14px',
                  padding: '10px',
                  minHeight: '50px',
                }}
              >
                <InputLabel
                  shrink={true}
                  sx={{
                    backgroundColor: isDarkMode ? 'rgb(51,51,51)' : '#FFF',
                    padding: '2px 10px 2px 10px',
                    borderRadius: '14px',
                  }}
                >
                  {t('color')}
                </InputLabel>{' '}
                <div className="row mt-2">
                  <div
                    onClick={() => {
                      setBlockData({
                        ...blockData,
                        color: 'transparent',
                      });
                    }}
                    className="col-2 color-picker"
                    style={{
                      borderRadius: blockData?.color === 'transparent' && '50%',
                      background: '#FFFFFF30',
                      backgroundImage: 'url(/assets/v3/img/transparent.png)',
                    }}
                  />
                  <div
                    onClick={() => {
                      setBlockData({
                        ...blockData,
                        color: 'white',
                      });
                    }}
                    className="col-2 color-picker"
                    style={{
                      borderRadius: blockData?.color === 'white' && '50%',
                      background: '#FFF',
                    }}
                  />

                  <div
                    onClick={() => {
                      setBlockData({
                        ...blockData,
                        color: 'grey',
                      });
                    }}
                    className="col-2 color-picker"
                    style={{
                      borderRadius: blockData?.color === 'grey' && '50%',
                      background: '#F4f4f4',
                    }}
                  />
                  <div
                    onClick={() => {
                      setBlockData({
                        ...blockData,
                        color: 'mainColor',
                      });
                    }}
                    className="col-2 color-picker"
                    style={{
                      borderRadius: blockData?.color === 'mainColor' && '50%',
                      background: businessPreference?.mainColor || '#000',
                    }}
                  />
                  <div
                    onClick={() => {
                      setBlockData({
                        ...blockData,
                        color: 'secColor',
                      });
                    }}
                    className="col-2 color-picker"
                    style={{
                      borderRadius: blockData?.color === 'secColor' && '50%',
                      background: businessPreference?.secColor || '#000',
                    }}
                  />
                </div>
              </FormControl>
            </div>
            <div style={{ paddingLeft: '20px' }} className="col-7 mt-1">
              <FormControl
                fullWidth
                margin="normal"
                sx={{
                  border: '1px solid lightgray',
                  borderRadius: '14px',
                  padding: '10px',
                  minHeight: '50px',
                }}
              >
                <InputLabel
                  shrink={true}
                  sx={{
                    backgroundColor: isDarkMode ? 'rgb(51,51,51)' : '#FFF',
                    padding: '2px 10px 2px 10px',
                    borderRadius: '14px',
                  }}
                >
                  {t('pattern')}
                </InputLabel>{' '}
                <div className="row mt-2">
                  <div
                    onClick={() => {
                      setBlockData({
                        ...blockData,
                        bgPattern: '',
                      });
                    }}
                    className="col-2 color-picker "
                    style={{
                      borderRadius: blockData?.bgPattern === '' && '50%',
                      background: '#FFF',
                    }}
                  />
                  <div
                    onClick={() => {
                      setBlockData({
                        ...blockData,
                        bgPattern: 'wavy',
                      });
                    }}
                    className="col-2 color-picker"
                    style={{
                      borderRadius: blockData?.bgPattern === 'wavy' && '50%',
                      background: '#F4f4f450',
                      backgroundImage: 'url(/assets/v3/img/wavy.png)',
                    }}
                  />
                  <div
                    onClick={() => {
                      setBlockData({
                        ...blockData,
                        bgPattern: 'moon',
                      });
                    }}
                    className="col-2 color-picker"
                    style={{
                      borderRadius: blockData?.bgPattern === 'moon' && '50%',
                      background: '#F4f4f450',
                      backgroundImage: 'url(/assets/v3/img/moon.png)',
                    }}
                  />
                  <div
                    onClick={() => {
                      setBlockData({
                        ...blockData,
                        bgPattern: 'paper',
                      });
                    }}
                    className="col-2 color-picker"
                    style={{
                      borderRadius: blockData?.bgPattern === 'paper' && '50%',
                      background: '#F4f4f450',
                      backgroundImage: 'url(/assets/v3/img/paper.png)',
                    }}
                  />
                  <div
                    onClick={() => {
                      setBlockData({
                        ...blockData,
                        bgPattern: 'polka',
                      });
                    }}
                    className="col-2 color-picker"
                    style={{
                      borderRadius: blockData?.bgPattern === 'polka' && '50%',
                      background: '#F4f4f450',
                      backgroundImage: 'url(/assets/v3/img/polka.png)',
                    }}
                  />
                </div>
              </FormControl>
            </div>
          </div>
          {blockData?.infos?.display && (
            <div className="col-12">
              <TextField
                label={t('notes')}
                value={blockData?.infos?.text}
                onChange={(e) =>
                  setBlockData({
                    ...blockData,
                    infos: { ...blockData?.infos, text: e.target.value },
                  })
                }
                fullWidth
              />
            </div>
          )}
          {blockData?.filter?.operator && (
            <div className="row">
              <div className="col-4">
                <Select
                  label={t('field')}
                  value={blockData?.filter?.field}
                  selections={[
                    { label: t('isDone'), value: 'isDone', id: 'isDone' },
                    {
                      label: t('isFeatured'),
                      value: 'isFeatured',
                      id: 'isFeatured',
                    },
                  ]}
                  onChange={(e, value) =>
                    setBlockData({
                      ...blockData,
                      filter: {
                        ...blockData?.filter,
                        field: value,
                      },
                    })
                  }
                  fullWidth
                />
              </div>
              <div className="col-4">
                <Select
                  label={t('operator')}
                  value={blockData?.filter?.operator}
                  selections={[
                    { label: '==', value: '==', id: '==' },
                    { label: '!=', value: '!=', id: '!=' },
                    { label: '>', value: '>', id: '>' },
                    { label: '>=', value: '>=', id: '>=' },
                    { label: '<', value: '<', id: '<' },
                    { label: '<=', value: '<=', id: '<=' },
                  ]}
                  onChange={(e, value) =>
                    setBlockData({
                      ...blockData,
                      filter: {
                        ...blockData?.filter,
                        operator: value,
                      },
                    })
                  }
                  fullWidth
                />
              </div>
              <div className="col-4">
                {blockData?.filter?.field === 'isDone' ||
                blockData?.filter?.field === 'isFeatured' ? (
                  <Select
                    label={t('value')}
                    value={blockData?.filter?.value}
                    selections={[
                      { label: t('yes'), value: true, id: true },
                      { label: t('no'), value: false, id: false },
                    ]}
                    onChange={(e, value) =>
                      setBlockData({
                        ...blockData,
                        filter: {
                          ...blockData?.filter,
                          value: value,
                        },
                      })
                    }
                    fullWidth
                  />
                ) : (
                  <TextField
                    label={t('value')}
                    value={blockData?.filter?.value}
                    onChange={(e) =>
                      setBlockData({
                        ...blockData,
                        filter: {
                          ...blockData?.filter,
                          value: e.target.value,
                        },
                      })
                    }
                    fullWidth
                  />
                )}
              </div>
            </div>
          )}
          {blockData?.order?.direction && (
            <div className="row">
              <div className="col-6">
                <TextField
                  label={t('field')}
                  value={blockData?.order?.field}
                  onChange={(e) =>
                    setBlockData({
                      ...blockData,
                      order: {
                        ...blockData?.order,
                        field: e.target.value,
                      },
                    })
                  }
                  fullWidth
                />
              </div>
              <div className="col-6">
                <Select
                  label={t('direction')}
                  value={blockData?.order?.direction}
                  selections={[
                    { label: t('asc'), value: 'asc', id: 'asc' },
                    {
                      label: t('descen'),
                      value: 'desc',
                      id: 'desc',
                    },
                  ]}
                  onChange={(e, value) =>
                    setBlockData({
                      ...blockData,
                      order: {
                        ...blockData?.filter,
                        direction: value,
                      },
                    })
                  }
                  fullWidth
                />
              </div>
            </div>
          )}
          {blockData?.groups && (
            <div className="col-12 mt-3">
              <Autocomplete
                multiple
                fullWidth
                options={formattedGroups || []}
                getOptionLabel={(option) => option?.label || ''}
                value={blockData?.groups}
                onChange={(event, newValue) => {
                  setBlockData({ ...blockData, groups: newValue });
                }}
                sx={{ maxWidth: '100%' }}
                renderInput={(params) => (
                  <TextFieldMUI
                    {...params}
                    sx={{
                      'MuiFormControl-root': {
                        width: '100%',
                        height: '55px',
                      },
                      '.MuiInputBase-input': {
                        padding: '2px 0px 2px 10px',
                        height: '55px',
                        width: '100%',
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '14px',
                        width: '100%',
                        height: '55px',
                        '&.Mui-focused fieldset': {
                          width: '100%',
                          borderColor: businessPreference?.mainColor || '#000',
                          boxShadow: `0 0 0 0.2rem ${
                            businessPreference?.mainColor + '20'
                          }`,
                        },
                      },
                    }}
                    label={t('selectGroup')}
                    variant="outlined"
                  />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </div>
          )}
        </div>
      </DialogWindow>
      <DrawerSide
        isDrawerOpen={openDrawerChanges}
        isCreation={true}
        title={t('requestChanges') + ' - ' + blockData?.name}
        handleDrawerClose={handleCloseChanges}
        isLoading={isLoadingChanges}
      >
        <RequestChanges
          formData={formData}
          setFormData={setFormData}
          handleSend={handleSaveChanges}
          structureId={structureId}
          existingFields={layout?.data?.map(
            (element) => element?.structureValue
          )}
        />
      </DrawerSide>
      <Paper
        elevation={0}
        sx={{
          backdropFilter:
            activeUser?.preferences?.blur === 0 ? '' : 'blur(5.8px)',
          backgroundColor:
            blockData?.color === 'transparent'
              ? '#FFFFFF35'
              : blockData?.color === 'grey'
              ? '#00000005'
              : activeUser?.preferences?.blur === 0
              ? '#FFFFFF99'
              : 'rgba(255, 255, 255, 0.65)',
          boxShadow:
            activeUser?.preferences?.blur === 0
              ? ''
              : `0  6px 6px rgba( 31, 38, 135, 0.05 )`,
          border: `1px solid rgba(255, 255, 255, 0.18)`,
          borderRadius: '14px',
          overflowY:
            childrenComponent === 'node'
              ? 'scroll'
              : noScroll
              ? 'hidden'
              : 'auto',
          overflowX: 'hidden',
          width: '100%',
          minHeight: '100%',

          height: '100%',
        }}
      >
        <div id="main-content">
          {blockData && childrenComponent !== 'node' && (
            <div
              style={{
                position: 'sticky',
                top: 0,
                padding: '4px',
                zIndex: 100,
                backgroundColor:
                  blockData?.color === 'transparent'
                    ? '#FFFFFF35'
                    : blockData?.color === 'grey'
                    ? '#00000005'
                    : activeUser?.preferences?.blur === 0
                    ? '#FFFFFF99'
                    : 'rgba(255, 255, 255, 0.65)',
                width: '100%',
                minHeight: isTablet ? '25px' : '35px',
              }}
            >
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex">
                  {blockData?.realtime ? (
                    <Tooltip title={t('realtime')}>
                      <PodcastsOutlined
                        fontSize={isTablet ? '10px' : 'small'}
                        htmlColor={
                          blockData?.color === 'transparent'
                            ? 'black'
                            : blockData?.color === 'white'
                            ? isDarkMode
                              ? '#ffffff'
                              : 'rgba(0,0,0,1)'
                            : blockData?.color === 'mainColor'
                            ? darkColor
                            : blockData?.color === 'secColor'
                            ? darkSecColor
                            : 'black'
                        }
                        sx={{
                          marginLeft: '8px',
                          marginTop: '3px',
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title={currentNameStructure}>
                      <CurrentIcon
                        fontSize={isTablet ? '10px' : 'small'}
                        htmlColor={
                          blockData?.color === 'transparent'
                            ? 'black'
                            : blockData?.color === 'white'
                            ? isDarkMode
                              ? '#ffffff'
                              : 'rgba(0,0,0,1)'
                            : blockData?.color === 'mainColor'
                            ? darkColor
                            : blockData?.color === 'secColor'
                            ? darkSecColor
                            : 'black'
                        }
                        sx={{
                          marginLeft: '8px',
                          marginTop: '4px',
                        }}
                      />
                    </Tooltip>
                  )}
                  <Typography
                    sx={{ marginLeft: '10px' }}
                    fontSize={isTablet ? '13px' : '16px'}
                    fontWeight={600}
                    color={
                      blockData?.color === 'transparent'
                        ? 'black'
                        : blockData?.color === 'white'
                        ? isDarkMode
                          ? '#ffffff'
                          : 'rgba(0,0,0,1)'
                        : blockData?.color === 'mainColor'
                        ? darkColor
                        : blockData?.color === 'secColor'
                        ? darkSecColor
                        : 'black'
                    }
                    variant="h6"
                  >
                    {blockData?.name}
                  </Typography>

                  {blockData?.infos?.display && (
                    <Tooltip title={blockData?.infos?.text}>
                      <InfoOutlined
                        fontSize={isTablet ? '10px' : 'small'}
                        htmlColor="#bababa"
                        sx={{
                          marginLeft: '8px',
                          marginTop: '2px',
                        }}
                      />
                    </Tooltip>
                  )}
                </div>
                <div className="align-right">
                  {blockData?.createBtn && !fromList && (
                    <Tooltip title={t('createNewElement')}>
                      <IconButton color="primary" onClick={openCreateModal}>
                        <Icons.AddCircleOutlineOutlined
                          sx={{
                            borderRadius: '50%',
                            padding: '1.5px',
                            color:
                              blockData?.color === 'white' ||
                              blockData?.color === 'transparent'
                                ? businessPreference?.mainColor
                                : 'white',
                            fontSize: '18px',
                          }}
                          color={'white'}
                          fontSize={isTablet ? '10px' : 'small'}
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                  {blockData?.filter?.operator && (
                    <Tooltip title={t(blockData?.filter?.field)}>
                      <IconButton disabled color="primary">
                        <FilterListOutlined
                          color={
                            blockData?.color === 'white' ||
                            blockData?.color === 'transparent'
                              ? 'main.primary'
                              : 'white'
                          }
                          fontSize={isTablet ? '10px' : 'small'}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                  {blockData?.order?.direction && (
                    <Tooltip title={t('order')}>
                      <IconButton color="primary" disabled>
                        {blockData?.order?.direction === 'asc' ? (
                          <ArrowUpward
                            color={
                              blockData?.color === 'white' ||
                              blockData?.color === 'transparent'
                                ? 'main.primary'
                                : 'white'
                            }
                            fontSize={isTablet ? '10px' : 'small'}
                          />
                        ) : (
                          <ArrowDownward
                            color={
                              blockData?.color === 'white' ||
                              blockData?.color === 'transparent'
                                ? 'main.primary'
                                : 'white'
                            }
                            fontSize={isTablet ? '10px' : 'small'}
                          />
                        )}
                      </IconButton>
                    </Tooltip>
                  )}
                  {blockData?.editBtn && !fromList && (
                    <Tooltip title={t('edit')}>
                      <IconButton color="primary" onClick={openDrawerEdit}>
                        <EditOutlined
                          color={
                            blockData?.color === 'white' ||
                            blockData?.color === 'transparent'
                              ? 'main.primary'
                              : 'white'
                          }
                          fontSize={isTablet ? '10px' : 'small'}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                  {blockData?.changesInProgress?.some(
                    (change) =>
                      change.tab === activeIndex && change.block === layout?.i
                  ) && (
                    <Tooltip title={t('changesInProgress')}>
                      <HourglassBottom
                        color={
                          blockData?.color === 'white' ||
                          blockData?.color === 'transparent'
                            ? 'main.primary'
                            : 'white'
                        }
                        fontSize={isTablet ? '10px' : 'small'}
                        style={{ paddingTop: '4px', marginBottom: '-2px' }}
                      />
                    </Tooltip>
                  )}
                  {!fromList && (
                    <IconButton color="primary" onClick={handleOpenMenu}>
                      <MoreVert
                        htmlColor={
                          blockData?.color === 'transparent'
                            ? 'black'
                            : blockData?.color === 'white'
                            ? isDarkMode
                              ? '#ffffff'
                              : 'rgba(0,0,0,1)'
                            : blockData?.color === 'mainColor'
                            ? darkColor
                            : blockData?.color === 'secColor'
                            ? darkSecColor
                            : 'black'
                        }
                        fontSize={isTablet ? '10px' : 'small'}
                      />
                    </IconButton>
                  )}

                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={menuOpen}
                    onClose={handleCloseMenu}
                  >
                    <MenuItem onClick={handleBlock}>
                      {t('updateBlock')}
                    </MenuItem>

                    <MenuItem onClick={handleRequestChanges}>
                      {t('requestChanges')}
                    </MenuItem>
                    {currentUser?.activeBusiness?.role !== 'EMPLOYEE' && (
                      <MenuItem onClick={handleUpdateRefresh}>
                        {t('refreshData')}
                      </MenuItem>
                    )}
                    {currentUser?.activeBusiness?.role === 'SUPER-ADMIN' && (
                      <MenuItem onClick={handleEditBlock}>
                        {t('updateContent')}
                      </MenuItem>
                    )}
                  </Menu>
                </div>
              </div>

              <Divider
                component="div"
                sx={{
                  marginLeft: '10px',
                  marginRight: '10px',
                  opacity: 0.1,
                  borderTop: `0.5px solid ${
                    blockData?.color === 'transparent'
                      ? 'black'
                      : blockData?.color === 'white'
                      ? isDarkMode
                        ? '#ffffff'
                        : 'rgba(0,0,0,1)'
                      : blockData?.color === 'mainColor'
                      ? darkColor
                      : blockData?.color === 'secColor'
                      ? darkSecColor
                      : 'black'
                  }`,
                }}
              />
            </div>
          )}
          {editMode ? (
            <div className="align-c row mt-4" style={{ position: 'relative' }}>
              <div className="mb-4">
                <FitScreenOutlined fontSize="medium" />
                <GeneralText
                  text={t('editMode')}
                  fontSize="12px"
                  size="medium"
                  primary={true}
                />
                <GeneralText
                  text={
                    'ID:' +
                    layout?.i +
                    ' - ' +
                    t('witdh') +
                    ': ' +
                    layout?.w +
                    ' - ' +
                    t('height') +
                    ': ' +
                    layout?.h
                  }
                  fontSize="11px"
                  size="regular"
                  primary={true}
                />
                <GeneralText
                  text={t('position') + ': ' + layout?.x + '.' + layout?.y}
                  fontSize="11px"
                  size="regular"
                  primary={true}
                />{' '}
              </div>
              <div
                className="hover"
                onMouseEnter={() => setDrag(false)}
                onMouseLeave={() => setDrag(true)}
                style={{ position: 'absolute', bottom: 0, zIndex: 20000 }}
                onClick={handleRemove}
              >
                <Typography
                  variant="body1"
                  color="error"
                  sx={{
                    fontSize: '10px',
                    fontWeight: 300,
                    marginTop: '18px',
                  }}
                >
                  {t('removeBlock')}
                </Typography>
              </div>
            </div>
          ) : (
            <>
              {layout?.data?.length === 0 && childrenComponent !== 'action' ? (
                <div className={className + ' row'}>
                  <div
                    className={
                      isTablet
                        ? 'align-center mt-1'
                        : heightPercentage < 30
                        ? ''
                        : 'mt-5'
                    }
                  >
                    <div className="align-c">
                      <Lottie options={defaultOptions} height={40} width={60} />
                    </div>
                    <div
                      className={heightPercentage < 30 ? 'mt-1' : 'mb-2 mb-2'}
                    >
                      <Typography
                        variant="h6"
                        fontSize={isTablet ? '11px' : '13px'}
                        component="div"
                        textAlign={'center'}
                      >
                        {t('empty' + 'empty')}
                      </Typography>
                    </div>
                    <div className={heightPercentage < 30 ? 'mb-1' : 'mb-2'}>
                      <Typography
                        variant="body2"
                        fontSize={isTablet ? '9px' : '10px'}
                        component="div"
                        textAlign={'center'}
                      >
                        {t('theseNoElement')}
                      </Typography>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className=""
                  style={{
                    padding:
                      blockData?.color === 'transparent'
                        ? '12px'
                        : noPadding
                        ? '3px'
                        : childrenComponent === 'node'
                        ? '0px'
                        : '12px',
                    height: '100%',
                    overflowX: 'hidden',
                    overflowY:
                      childrenComponent === 'node'
                        ? 'hidden'
                        : noScroll
                        ? 'hidden'
                        : 'auto',
                  }}
                >
                  {layout?.isRestricted && (
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 1000,
                        width: '100%',
                        height: '100%',
                        backdropFilter: 'blur(2px)',
                        backgroundColor: isDarkMode ? '#33333395' : '#FFFFFF95',
                      }}
                    >
                      <div className="align-c">
                        <div className="mt-5">
                          <LockOutlined
                            fontSize="large"
                            color="error"
                            sx={{ color: 'error' }}
                          />
                        </div>
                        <GeneralText
                          text={t('restricted')}
                          fontSize="14px"
                          size="medium"
                          primary={true}
                        />
                        <GeneralText
                          text={t('restrictedMsg')}
                          fontSize="12px"
                          size="regular"
                          primary={true}
                        />
                      </div>
                    </div>
                  )}
                  {blockTransit?.i === layout?.i &&
                    activeIndex === elementDetails?.index && (
                      <PerfectScrollbar>
                        <BlockLayoutScroll
                          elementDetails={blockTransit}
                          layout={layout}
                          fromList={fromList}
                          childrenComponent={childrenComponent}
                          heightPercentage={heightPercentage}
                          handleClose={handleClose}
                          handleAddItem={handleAddItem}
                        />
                      </PerfectScrollbar>
                    )}
                </div>
              )}
            </>
          )}
        </div>
      </Paper>
    </ErrorBoundary>
  );
};

export default BlockLayout;
