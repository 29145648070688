import React, { useState, useEffect } from 'react';
import ErrorBoundary from '../../components/@generalComponents/ErrorBoundary';
import * as drawerActions from '../../redux/actions-v2/drawer-actions';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  List,
  ListItem,
  ListItemText,
  Box,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
import GeneralText from '../general-components/GeneralText';
import Avatar from '../general-components/Avatar';
import { PermMediaOutlined } from '@mui/icons-material';
import DialogWindow from '../general-components/DialogWindow';
import { setGeneralStatus } from '../../redux/actions-v2/coreAction';

const ListLayout = ({ elementDetails, layout }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDialogImg, setOpenDialogImg] = useState(false);
  const [images, setImages] = useState([]);
  const [list, setList] = useState([]);

  const structure = elementDetails?.structure;

  const container = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.8,
      },
    },
  };

  const itemLine = {
    hidden: { x: -50, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.4,
        ease: 'easeOut',
      },
    },
  };

  const filterBy = (list, condition) => {
    if (!condition) return list;

    const { field, operator, value } = condition;
    return list.filter((item) => {
      const itemIndex = item.findIndex(
        (entry) => entry.structureValue === field
      );
      if (itemIndex === -1) return false;

      const itemValue = item[itemIndex].value;
      switch (operator) {
        case '==':
          return itemValue === value;
        case '!=':
          return itemValue !== value;
        case '<':
          return itemValue < value;
        case '>':
          return itemValue > value;
        case '<=':
          return itemValue <= value;
        case '>=':
          return itemValue >= value;
        default:
          return true;
      }
    });
  };

  useEffect(() => {
    let resultData = Array.isArray(elementDetails?.data)
      ? elementDetails?.data
      : [];

    // First, filter data if a filter condition is provided
    if (layout?.filter?.value) {
      resultData = filterBy(resultData, layout.filter);
    }

    // Then, order data if an order is specified
    if (layout?.order?.direction) {
      const orderBy = (list, field, direction) => {
        const valueIndex = list[0]?.findIndex(
          (item) => item?.structureValue === field
        );

        if (valueIndex === -1) {
          console.warn('Field not found in the data');
          return list;
        }

        return list?.sort((a, b) => {
          const aValue =
            a[valueIndex]?.value?.seconds ||
            a[valueIndex]?.value?._seconds ||
            a[valueIndex]?.value;
          const bValue =
            b[valueIndex]?.value?.seconds ||
            b[valueIndex]?.value?._seconds ||
            b[valueIndex]?.value;

          return direction === 'desc' ? bValue - aValue : aValue - bValue;
        });
      };

      resultData = orderBy(
        resultData,
        layout?.order?.field,
        layout?.order?.direction
      );
    }

    setList(resultData);
  }, [elementDetails?.data]);

  const handleClose = () => {
    dispatch(drawerActions.viewElement({ isDrawerOpen: false }));
  };

  const handleUpdateElementDrawer = (key, value, index) => {
    const newList = list?.map((item, i) => {
      if (i === index) {
        const elementIndexToUpdate = item?.findIndex(
          (entry) => entry.structureValue === key
        );

        if (elementIndexToUpdate === -1) return item;

        // Create a new array with the updated element
        return item?.map((element, j) => {
          if (j === elementIndexToUpdate) {
            // Update the element
            return {
              ...element,
              value:
                element?.typeValue === 'date' ||
                element?.typeValue === 'date-time'
                  ? { _seconds: value?.unix(), seconds: value?.unix() }
                  : value,
              transformedValue:
                element?.typeValue === 'status'
                  ? element?.transformedValue
                  : element?.typeValue === 'date' ||
                    element?.typeValue === 'date-time'
                  ? { _seconds: value?.unix(), seconds: value?.unix() }
                  : value,
            };
          }
          return element;
        });
      }
      return item;
    });

    setList(newList);
  };

  const handleDeleteElementDrawer = (index) => {
    const newList = list?.filter((item, i) => i !== index);
    setList(newList);
    handleClose();
  };

  const handleQuickview = (item, index) => {
    if (item[0]?.action === 'navigate') {
      navigateItem(item, 'item');
    } else if (item[0]?.action === 'openParent') {
      navigateItem(item, 'parent');
    } else if (item[0]?.action === 'quickview') {
      dispatch(
        drawerActions.viewElement({
          isDrawerOpen: true,
          item: {
            id: item[0]?.primaryData?.elementId,
            documentPath: item[0]?.primaryData?.elementPath,
            name: item[0]?.primaryData?.name,
            targetId: item[0]?.primaryData?.targetId,
            lastUpdate: item[0]?.primaryData?.lastUpdate,
          },
          handleDrawerClose: handleClose,
          handleUpdate: (key, value) =>
            handleUpdateElementDrawer(key, value, index),
          handleDelete: () => handleDeleteElementDrawer(index),
          type: 'view',
        })
      );
    } else if (item[0]?.action === 'edit') {
      dispatch(
        drawerActions.viewElement({
          isDrawerOpen: true,
          item: {
            id: item[0]?.primaryData?.elementId,
            documentPath: item[0]?.primaryData?.elementPath,
            name: item[0]?.primaryData?.name,
            targetId: item[0]?.primaryData?.targetId,
            lastUpdate: item[0]?.primaryData?.lastUpdate,
          },
          handleDrawerClose: handleClose,
          handleUpdate: (key, value) =>
            handleUpdateElementDrawer(key, value, index),
          handleDelete: () => handleDeleteElementDrawer(index),
          type: 'edit',
        })
      );
    } else if (item[0]?.action === 'none') {
      return;
    }
  };

  const navigateItem = (item, type) => {
    if (type === 'item') {
      navigate(
        '/app/element/' +
          item[0]?.collectionField +
          '/' +
          item[0]?.structureId +
          '/' +
          item[0]?.primaryData?.elementId +
          '?tab=0'
      );
    } else if (type === 'parent') {
      navigate(
        '/app/element/' +
          item[0]?.primaryData?.dependencyDetails?.collectionField +
          '/' +
          item[0]?.primaryData?.dependencyDetails?.structureIdentifiant +
          '/' +
          item[0]?.primaryData?.dependencyDetails?.id +
          '?tab=0'
      );
    }
    handleClose();
  };

  // const totalsFinal =
  //   list?.length > 0 &&
  //   list?.reduce((totals, item) => {
  //     if (Array.isArray(item)) {
  //       item.forEach((col, idx) => {
  //         if (col?.typeValue === 'money') {
  //           totals[idx] =
  //             (totals[idx] || 0) + parseFloat(col?.transformedValue || 0);
  //         }
  //       });
  //     }
  //     return totals;
  //   }, []);

  const handleOpenDialog = (photos) => {
    setOpenDialogImg(true);
    setImages(photos);
  };

  const openNewTabImg = (image) => {
    window.open(image, '_blank');
  };

  const isSmall = layout?.w <= 4;

  function resolveComponent() {
    switch (isSmall) {
      case true:
        return (
          <motion.ul
            variants={container}
            initial="hidden"
            animate="visible"
            style={{ listStyleType: 'none', padding: 0, margin: 0 }}
          >
            <List dense sx={{ width: '100%' }}>
              {list?.length > 0 &&
                Array.isArray(list) &&
                list?.map((item, index) => {
                  return (
                    <motion.li
                      key={item[0]?.primaryData?.elementId}
                      variants={itemLine}
                    >
                      <ListItem
                        button
                        dense
                        sx={{
                          padding: '0px !important',
                          display: 'flex',
                          justifyContent: 'space-between',
                          borderBottom:
                            index !== list?.length - 1
                              ? '0.3px solid #00000050'
                              : '',
                        }}
                        alignItems="flex-start"
                        key={index}
                      >
                        {item?.length > 0 &&
                          item?.map((col, idx) => {
                            return (
                              <Box
                                sx={{
                                  width: `50%`,
                                  borderLeft:
                                    idx === 0 ? '' : '0.3px solid lightgray',
                                  paddingLeft: '4px',
                                  paddingRight: '2px',
                                  height: '46px',
                                }}
                                key={idx + col?.structureValue}
                              >
                                {(col?.typeValue === 'text' ||
                                  col?.typeValue === 'string' ||
                                  col?.typeValue === 'number' ||
                                  col?.typeValue === 'money' ||
                                  col?.typeValue === 'date' ||
                                  col?.typeValue === 'date-time' ||
                                  col?.typeValue === 'status') && (
                                  <ListItemText
                                    onClick={() => handleQuickview(item, index)}
                                    primary={
                                      <GeneralText
                                        primary={
                                          col?.valueColor === 'primary'
                                            ? true
                                            : false
                                        }
                                        size={
                                          idx === 0
                                            ? 'bold'
                                            : col?.weight || 'medium'
                                        }
                                        keyStructure={col?.structureValue}
                                        fontSize="10.5px"
                                        structureId={col?.structureId}
                                        text={col?.transformedValue || ''}
                                        type={col?.typeValue || 'string'}
                                        color={col?.valueColor}
                                      />
                                    }
                                    secondary={
                                      <>
                                        {col?.sub?.value && (
                                          <GeneralText
                                            primary={true}
                                            size="regular"
                                            fontSize="10px"
                                            keyStructure={
                                              col?.sub?.structureValue
                                            }
                                            structureId={structure?.id}
                                            text={
                                              col.sub?.transformedValue ?? ''
                                            }
                                            type={col.sub?.typeValue ?? 'text'}
                                          />
                                        )}
                                      </>
                                    }
                                  />
                                )}

                                {(col?.typeValue === 'avatar' ||
                                  col?.typeValue === 'media-single') && (
                                  <ListItemAvatar
                                    onClick={() => handleQuickview(item, index)}
                                  >
                                    <Avatar
                                      img={col?.value ?? ''}
                                      name={col?.value ?? ''}
                                      alt={col?.value ?? ''}
                                      sx={{
                                        maxWidth: `${'40px !important'}`,
                                        maxHeight: `${'40px !important'}`,
                                        borderRadius: '6px !important',
                                        padding: '4px',
                                      }}
                                    />
                                  </ListItemAvatar>
                                )}
                                {col?.typeValue === 'media' && (
                                  <ListItemButton
                                    onClick={() => handleOpenDialog(col?.value)}
                                  >
                                    <PermMediaOutlined />
                                  </ListItemButton>
                                )}
                              </Box>
                            );
                          })}
                      </ListItem>
                    </motion.li>
                  );
                })}
            </List>
          </motion.ul>
        );

      default:
        return (
          <motion.ul
            variants={container}
            initial="hidden"
            animate="visible"
            style={{ listStyleType: 'none', padding: 0, margin: 0 }}
          >
            <List dense sx={{ width: '100%' }}>
              {list?.length > 0 &&
                Array.isArray(list) &&
                list?.map((item, index) => {
                  return (
                    <motion.li
                      key={item[0]?.primaryData?.elementId}
                      variants={itemLine}
                    >
                      <ListItem
                        sx={{
                          overflow: 'hidden',
                          cursor: 'pointer',
                          height: '46px',
                          m: 0,
                          p: 0,
                          flexWrap: 'nowrap',
                          borderBottom:
                            index !== list?.length - 1
                              ? '0.3px solid #00000050'
                              : '',
                        }}
                        button
                        className="middle-content"
                        dense
                        alignItems="flex-start"
                        key={index}
                      >
                        {Array.isArray(item) &&
                          item?.map((col, idx) => {
                            const columnWidth = `${col?.width}%`;
                            return (
                              <Box
                                sx={{
                                  width: columnWidth,
                                  position: 'relative',
                                  overflow: 'hidden',
                                  paddingRight: '4px',
                                  cursor: 'pointer',
                                  flexWrap: 'nowrap',
                                  marginLeft: '6px',
                                  borderLeft:
                                    idx === 0 ? '' : '0.3px solid lightgray',
                                  paddingLeft: '6px',
                                  paddingRight: '3px',
                                  height: '46px',
                                }}
                                key={idx + col?.structureValue}
                              >
                                {(col?.typeValue === 'text' ||
                                  col?.typeValue === 'string' ||
                                  col?.typeValue === 'number' ||
                                  col?.typeValue === 'money' ||
                                  col?.typeValue === 'date' ||
                                  col?.typeValue === 'date-time' ||
                                  col?.typeValue === 'status' ||
                                  col?.typeValue === 'selection' ||
                                  col?.typeValue === 'boolean' ||
                                  col?.typeValue === 'assignedTo') && (
                                  <ListItemText
                                    onClick={() => handleQuickview(item, index)}
                                    primary={
                                      <GeneralText
                                        primary={
                                          col?.valueColor === 'primary'
                                            ? true
                                            : false
                                        }
                                        size={
                                          idx === 0
                                            ? 'bold'
                                            : col?.weight || 'medium'
                                        }
                                        fontSize="11px"
                                        structureId={col?.structureId}
                                        text={col?.transformedValue ?? ''}
                                        keyStructure={col?.structureValue}
                                        type={col?.typeValue || 'string'}
                                        classNameComponent="clickable"
                                        color={col?.valueColor}
                                      />
                                    }
                                    secondary={
                                      <>
                                        {col?.sub?.value && (
                                          <GeneralText
                                            primary={true}
                                            size="regular"
                                            fontSize="10px"
                                            classNameComponent="clickable"
                                            structureId={structure?.id}
                                            text={
                                              col.sub?.transformedValue ?? ''
                                            }
                                            keyStructure={
                                              col?.sub?.structureValue
                                            }
                                            type={col.sub?.typeValue ?? 'text'}
                                          />
                                        )}
                                      </>
                                    }
                                  />
                                )}

                                {(col?.typeValue === 'avatar' ||
                                  col?.typeValue === 'media-single') && (
                                  <ListItemAvatar
                                    onClick={() => handleQuickview(item, index)}
                                  >
                                    <Avatar
                                      img={col?.value ?? ''}
                                      name={col?.value ?? ''}
                                      alt={col?.value ?? ''}
                                      sx={{
                                        maxWidth: `${'40px !important'}`,
                                        maxHeight: `${'40px !important'}`,
                                        borderRadius: '6px !important',
                                        padding: '4px',
                                      }}
                                    />
                                  </ListItemAvatar>
                                )}
                                {col?.typeValue === 'media' && (
                                  <ListItemButton
                                    onClick={() => handleOpenDialog(col?.value)}
                                  >
                                    <PermMediaOutlined />
                                  </ListItemButton>
                                )}
                              </Box>
                            );
                          })}
                      </ListItem>
                    </motion.li>
                  );
                })}
              {/* <motion.li
                key="totals"
                variants={{
                  hidden: { x: -50, opacity: 0 },
                  visible: {
                    x: 0,
                    opacity: 1,
                    transition: { duration: 0.4, ease: 'easeOut' },
                  },
                }}
              >
                <ListItem
                  sx={{
                    overflow: 'hidden',
                    cursor: 'default',
                    height: '46px',

                    ml: 0,
                    marginTop: '7px',
                    mr: 0,
                    p: 0,
                    flexWrap: 'nowrap',
                    borderTop: '0.85px solid #00000090',
                    justifyContent: 'space-between',
                  }}
                  dense
                  alignItems="flex-start"
                >
                  {Array.isArray(list) &&
                    list?.[0]?.map((col, idx) => (
                      <Box
                        key={`total-${idx}`}
                        sx={{
                          width: `${col?.width || 'auto'}%`,
                          paddingLeft: '10px',
                          paddingRight: '6px',
                          paddingTop: '6px',
                          textAlign: 'left',
                          fontWeight: 'bold',
                          fontSize: '11px',
                        }}
                      >
                        {col?.typeValue === 'money'
                          ? `${(totalsFinal?.[idx] / 10000)?.toFixed(2)}$`
                          : ''}
                      </Box>
                    ))}
                </ListItem>
              </motion.li> */}
            </List>
          </motion.ul>
        );
    }
  }

  return (
    <ErrorBoundary>
      <DialogWindow
        title={t('images')}
        open={openDialogImg}
        onClose={() => setOpenDialogImg(false)}
        size={'medium'}
      >
        <div
          style={{
            width: '40vh',
          }}
        >
          {images?.length > 0 &&
            images?.map((image, index) => (
              <img
                onClick={() => openNewTabImg(image)}
                key={index}
                src={image}
                alt="img"
                style={{ width: '80px', height: 'auto' }}
              />
            ))}
        </div>
      </DialogWindow>
      <div style={{ overflowX: 'hidden', marginTop: '-15px' }}>
        <>{resolveComponent()}</>
      </div>
    </ErrorBoundary>
  );
};

export default ListLayout;
